import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";


function AppWithCallbackAfterRender() {
  return <App tab="home" />
}
const container = document.getElementById('root');
const root = createRoot(container);


root.render(
  <Router>
    <Auth0ProviderWithNavigate>
      <AppWithCallbackAfterRender />
    </Auth0ProviderWithNavigate>
    </Router>
);
reportWebVitals(sendToVercelAnalytics);
