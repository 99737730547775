import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
import LoginButton from './login';
import LogoutButton from './logout';

const Navbar = () => {
    const { user, isAuthenticated } = useAuth0();
    return (
        <nav className="navbar">
            <h1>MyRecify</h1>
            <div className="links">
                <Link to="/"><Button>Home</Button></Link>
                { user && <Link to="/profile"><Button>Profile</Button></Link> }
                { !isAuthenticated && <LoginButton /> }
                { isAuthenticated && <LogoutButton /> }
            </div>
        </nav>
    )
}

export default Navbar;