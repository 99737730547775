import ReactGA from "react-ga4";
import { inject } from '@vercel/analytics';
import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import Recipe from './Recipe';
import NotFound from "./NotFound";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Profile from './profile';
import Favorites from './Favorites';
import { useAuth0 } from "@auth0/auth0-react";


const TRACKING_ID = "G-Q3M3TXL1D4"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

// consider adding helmet to better set meta tag for mui
// https://www.devauthority.com/react/add-head-elements-to-react-with-react-helmet/
// <meta name="viewport" content="initial-scale=1, width=device-width" />

function App() {
  inject();
  const { user, isAuthenticated, isLoading } = useAuth0();
  return (
    <div className="App">
      <header className="App-header">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/recipes/:id" element={<Recipe />}></Route>
          {isAuthenticated && (<Route path="/profile" element={<Profile />}></Route>)}
          <Route path="/:u/favorites" element={<Favorites />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </header>
    </div>
  );
}

export default App;
