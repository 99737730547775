import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";


const Favorites = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { u } = useParams();

  console.log("u = " + u);
  console.log("isLoading = " + isLoading);

  if (isLoading) {
    return <div>Loading ...</div>;
  }


  return (
    isAuthenticated && (
      <div>
        <h1>{user["https://myrecify.com/username"]}'s Favorites</h1>
      </div>
    )
  );
};

export default Favorites;